<script setup>
import { ref } from 'vue';
import { useActorStore } from '@/stores/actors.js';
import { isExisting } from '@/utilities/helpers.js';
import isEmpty from 'lodash/isEmpty';
import { setFieldValidity } from '@/utilities/vue.js';

const props = defineProps({
  actor: {
    type: Object,
    required: true,
  },
});

const emit = defineEmits(['close']);

const actorsStore = useActorStore();
const password = ref(null);
const passwordParentRef = ref(null);
const confirmPassword = ref(null);
const confirmPasswordParentRef = ref(null);

function close() {
  emit('close', { action: 'close' });
}

async function setPassword() {
  setFieldValidity(passwordParentRef.value);
  setFieldValidity(confirmPasswordParentRef.value);
  if (isEmpty(password.value)) {
    setFieldValidity(passwordParentRef.value, 'danger', 'Enter a new password first');
    return;
  }
  if (confirmPassword.value !== password.value) {
    setFieldValidity(confirmPasswordParentRef.value, 'danger', 'Re-enter the same password first before');
    return;
  }
  if (isExisting(props.actor.id)) {
    await actorsStore.saveChanges(props.actor.id, { password: password.value });
  } else {
    await actorsStore.save({ ...props.actor, password: password.value });
  }

  close();
}
</script>

<template lang="pug">
.modal-card
  header.modal-card-head
    p.modal-card-title Set login password
    button.delete(aria-label="close" @click="close")
  section.modal-card-body
    OField(
      ref="passwordParentRef"
      label="Password"
    )
      OInput(
        v-model="password"
        type="password"
        autocomplete="new-password"
        required
        clearable
      )
    OField(
      ref="confirmPasswordParentRef"
      label="Confirm Password"
    )
      OInput(
        v-model="confirmPassword"
        type="password"
        autocomplete="off"
        required
        clearable
      )
  footer.modal-card-foot
    OButton(variant="primary" @click="setPassword") Set password
    OButton(@click="close") Cancel
</template>

<style scoped>

</style>
