<script setup>
import { useAuthStore } from '@/stores/auth';
import { checkForm, setFieldValidity } from '@/utilities/vue';
import { computed, getCurrentInstance, nextTick, onBeforeUnmount, ref } from 'vue';
import { showError, showNotification } from '@/dialogs';

const NOT_ALLOWED_CHARACTERS = /[^23456789BCDFGHJKMNPQRTVWXY]/g;

const emit = defineEmits(['signedin']);

const Modes = Object.freeze({
  START: Symbol('START'),
  // new account
  SIGNUP: Symbol('SIGNUP'),
  VERIFY_EMAIL: Symbol('VERIFY_EMAIL'),
  // existing account
  SIGNIN: Symbol('SIGNIN'),
  PASSWORD: Symbol('PASSWORD'),
  TOTP_CODE: Symbol('TOTP'),
});

const mode = ref(Modes.START);

const email = ref('');
const emailRef = ref(null);
const emailParentRef = ref(null);
const lastEmail = ref('');

const password = ref('');
const passwordRef = ref(null);
const passwordParentRef = ref(null);

const verifyEmail = ref('');
const verifyEmailRef = ref(null);
const verifyEmailParentRef = ref(null);

const totpCode = ref('');
const totpRequiredCheck = ref(true);
const totpCodeParentRef = ref(null);
const totpCodeRef = ref(null);

const disabled = ref(false);
const notification = ref(null);

const mainLabel = computed(() => {
  switch (mode.value) {
  case Modes.START:
    return 'Do you have a profile?';
  case Modes.SIGNUP:
  case Modes.VERIFY_EMAIL:
    return 'Create a new profile';
  case Modes.SIGNIN:
    return 'Sign in to an existing profile';
  case Modes.TOTP_CODE:
    return 'Sign in with emailed code';
  case Modes.PASSWORD:
    return 'Sign in with password';
  }
  return '';
});

onBeforeUnmount(() => {
  closeNotification();
});

const vm = getCurrentInstance();

// methods
function setMode(newMode) {
  mode.value = newMode;
}

function setModeAfterEmail(newMode) {
  if (!email.value) {
    setFieldValidity(emailParentRef.value, 'danger', 'Please enter a valid email');
  } else if (newMode === Modes.TOTP_CODE) {
    signup();
  } else {
    setMode(newMode);
  }
}

function isMode(...checks) {
  return checks.includes(mode.value);
}

async function sendNewCode() {
  await signup(true);
  await nextTick(() => {
    totpCodeRef.value.focus();
  });
}

async function signup(skipTotpCode = false) {
  if (mode.value !== Modes.PASSWORD && lastEmail.value !== email.value) {
    mode.value = Modes.SIGNUP;
    verifyEmail.value = '';
    await nextTick();
    lastEmail.value = email.value;
  }
  if (skipTotpCode) {
    totpRequiredCheck.value = false;
    await nextTick();
  }
  await setFieldValidity(verifyEmailParentRef.value);
  closeNotification();
  const invalid = checkForm(vm);
  totpRequiredCheck.value = true;

  if (invalid.length > 0) {
    return;
  }

  disabled.value = true;
  const options = {};
  if (!skipTotpCode && totpCode.value) {
    options.otp = cleanTotpCode(totpCode.value);
  }
  if (verifyEmail.value) {
    options.verify_email = verifyEmail.value;
  }
  if (mode.value === Modes.PASSWORD) {
    options.password = password.value;
  }
  useAuthStore().authenticateWizard(email.value, options).then(() => {
    emit('signedin');
  }).catch((e) => {
    console.error('sign in error', e);
    switch (e.code) {
    case 'AUTHENTICATION_LINK':
      mode.value = Modes.TOTP_CODE;
      totpCode.value = '';
      notification.value = showNotification({
        message: 'Check your email for a sign in code',
        variant: 'warning',
        position: 'top',
        indefinite: true,
        closable: true,
      });
      nextTick(() => {
        totpCodeRef.value.focus();
      });
      break;
    case 'CHECK_EMAIL':
      if (verifyEmail.value) {
        setFieldValidity(verifyEmailParentRef.value, 'danger', 'Email does not match');
      }
      mode.value = Modes.VERIFY_EMAIL;
      notification.value = showNotification({
        message: 'Please re-enter your email to verify',
        variant: 'warning',
        indefinite: true,
        closable: true,
        position: 'top',
      });
      nextTick(() => {
        verifyEmailRef.value.focus();
      });
      break;
    case 'INVALID_EMAIL':
    case 'NO_EMAIL':
      setFieldValidity(emailParentRef.value, 'danger', 'Please enter a valid email');
      nextTick(() => {
        emailRef.value.focus();
      });
      break;
    case 'INVALID_CODE':
      setFieldValidity(totpCodeParentRef.value, 'danger', 'Please enter the code from the email. Or request a new code');
      nextTick(() => {
        totpCodeRef.value.focus();
      });
      break;
    case 'INVALID_PASSWORD':
      setFieldValidity(passwordParentRef.value, 'danger', 'Your email or password are incorrect or you do not have a password set for your profile');
      nextTick(() => {
        passwordRef.value.focus();
      });
      break;
    default:
      notification.value = showError({
        message: 'Failed to signup',
        position: 'top',
      });
    }
  }).finally(() => {
    disabled.value = false;
  });
}

function closeNotification() {
  notification.value?.close();
  notification.value = null;
}

function cleanTotpCode(code) {
  return code.toLocaleUpperCase().replaceAll(NOT_ALLOWED_CHARACTERS, '');
}

function cleanTotpCodeInput(event) {
  if (event.data) {
    let allowed = cleanTotpCode(event.data);
    const existingLength = event.target.value.length - (event.target.selectionEnd - event.target.selectionStart);
    allowed = allowed.substring(0, event.target.maxLength - existingLength);
    event.target.setRangeText(allowed, event.target.selectionStart, event.target.selectionEnd, 'end');
    totpCode.value = event.target.value;
    event.preventDefault();
  }
}
</script>

<template lang="pug">
section.hero
  .hero-head
    figure.image.is-centered(style="max-width: 400px;")
      img(src="@/assets/bnb-logo-800.png")
  .hero-body
    section.notification.is-light.is-info
      p.
        Welcome to Beef & Boards Casting Manager page.
        Thank you for taking the time to put in as little or as much info as you’d like.
        You may come back anytime to update your profile and only need your email address.
      br
      p.
        Beef & Boards will not use any information you provide for anything except
        casting purposes.

    p.subtitle
      | {{ mainLabel }}
      OButton.ml-1(
        v-if="!isMode(Modes.START)"
        rounded
        size="small"
        @click="setMode(Modes.START)"
      ) Start over

    OField(
      v-if="!isMode(Modes.START)"
      ref="emailParentRef"
      label="E-Mail"
    )
      OInput(
        ref="emailRef"
        v-model.trim="email"
        type="email"
        icon="envelope"
        placeholder="E-Mail"
        required
        autocomplete="email"
        :disabled="disabled"
        @change="totpCodeRequired = false"
        @keyup.enter="signup()"
      )
    OField(
      v-if="isMode(Modes.VERIFY_EMAIL)"
      ref="verifyEmailParentRef"
      label="Verify E-Mail"
    )
      OInput(
        ref="verifyEmailRef"
        v-model.trim="verifyEmail"
        type="email"
        icon="envelope"
        placeholder="Reenter E-Mail"
        required
        autocomplete="off"
        :disabled="disabled"
        @keyup.enter="signup()"
      )

    OField(
      v-if="isMode(Modes.TOTP_CODE)"
      ref="totpCodeParentRef"
      label="Code"
    )
      OInput.totp_code(
        ref="totpCodeRef"
        v-model.trim="totpCode"
        type="text"
        maxlength="5"
        icon="lock"
        placeholder="Enter the 5-character code from your email"
        :required="totpRequiredCheck"
        :disabled="disabled"
        @beforeinput="cleanTotpCodeInput"
        @keyup.enter="signup()"
      )
    OField(
      v-if="isMode(Modes.PASSWORD)"
      ref="passwordParentRef"
      label="Password"
    )
      OInput.password(
        ref="passwordRef"
        v-model="password"
        type="password"
        icon="lock"
        placeholder="Enter your password"
        required
        :disabled="disabled"
        @keyup.enter="signup()"
      )





    .buttons
      template(v-if="isMode(Modes.START)")
        OButton(size="large" @click="setMode(Modes.SIGNUP)") New profile
        OButton(size="large" @click="setMode(Modes.SIGNIN)") Existing profile
      template(v-else-if="isMode(Modes.SIGNUP, Modes.VERIFY_EMAIL)")
        OButton(
          variant="primary"
          :disabled="disabled"
          @click="signup()"
        ) Sign up with Email
      template(v-else-if="isMode(Modes.SIGNIN)")
        OButton(size="large" @click="setModeAfterEmail(Modes.TOTP_CODE)") With an emailed code
        OButton(size="large" @click="setModeAfterEmail(Modes.PASSWORD)") With a password
      template(v-else-if="isMode(Modes.TOTP_CODE)")
        OButton(
          v-tooltip="'Enter the code sent to your email'"
          variant="primary"
          :disabled="disabled"
          @click="signup()"
        ) Sign in
        OButton(
          v-tooltip="'Send another email code'"
          variant="secondary"
          :disabled="disabled"
          @click="sendNewCode()"
        ) Send new code
      template(v-else-if="isMode(Modes.PASSWORD)")
        OButton(
          v-tooltip="'Sign in with email and password'"
          variant="primary"
          :disabled="disabled"
          @click="signup()"
        ) Sign in
</template>

<style scoped>

</style>
