import { computed, readonly, ref, watch } from 'vue';
import { useActorStore } from '@/stores/actors.js';
import { buildChanges, cloneDeep } from '@/utilities/helpers.js';
import { showError } from '@/dialogs/index.js';
import { isGraphQLError } from '@/api/utils.js';
import { ReportError } from '@/error_handling.js';

export const WizardActorEditingKey = Symbol('WIZARD_ACTOR_EDITING');

export function useWizardActorEditing(actorIdRef, emailRef) {
  const isDirtyCore = ref(false);
  const isDirtyExtra = ref(false);
  const isDirty = computed(() => isDirtyCore.value || isDirtyExtra.value);
  const originalActor = ref({});
  const actor = ref({
    location: {},
    status: 'IN_WIZARD',
  });
  const validityChecks = [];
  const saveHooks = [];

  function updateActor() {
    if (actorIdRef.value) {
      originalActor.value = cloneDeep(useActorStore().retrieve(actorIdRef.value)) || {};
      actor.value = cloneDeep(originalActor.value);
    } else {
      originalActor.value = {};
      actor.value = {
        email: emailRef.value,
        location: {},
        status: 'IN_WIZARD',
      };
    }
  }

  function calcDirty(originalActor, actor, isDirtyRef) {
    const changes = buildChanges(originalActor.value, actor.value);

    isDirtyRef.value = Object.keys(changes).length > 0;
  }

  async function saveActor() {
    const invalid = validityChecks.reduce((a, v) => a.concat(v()), []);
    if (invalid.length === 0) {
      try {
        const updatedActor = await useActorStore().save(actor.value);
        updateActor(actor, originalActor);

        for (const hook of saveHooks) {
          const result = await hook(updatedActor);
          if (result !== true) {
            showError({ message: result || 'Unable to save actor' });
            return false;
          }
        }

        return true;
      } catch (ex) {
        if (isGraphQLError(ex)) {
          showError({ message: ex.message });
        }
        ReportError(ex);
        return false;
      }
    } else {
      console.info(invalid);
      showError({ message: 'Form is not valid! Please check the fields.' });
      return false;
    }
  }


  async function finishActor() {
    if (actor.value.status !== 'IN_REVIEW') {
      actor.value.status = 'IN_REVIEW';
    }
    return await saveActor();
  }


  watch(
    [actorIdRef, emailRef],
    () => updateActor(),
  );

  watch(
    actor,
    () => calcDirty(originalActor, actor, isDirtyCore),
    { deep: true },
  );

  function setExtraDirty(value) {
    isDirtyExtra.value = value;
  }

  function registerValidityCheck(callback) {
    validityChecks.push(callback);
  }

  function registerSaveHook(callback) {
    saveHooks.push(callback);
  }

  return {
    isDirty,
    actor,
    originalActor: readonly(originalActor),
    setExtraDirty,
    registerValidityCheck,
    registerSaveHook,
    saveActor,
    finishActor,
  };
}
